<template>
  <div
    v-click-outside="collapse"
    role="button"
    tabindex="0"
    class="legacy-time-picker relative"
    data-test="time-picker"
    @keypress.stop.prevent="toggle"
    @click.stop.prevent="toggle"
    @keydown.enter.prevent="toggle"
    @focusout="focusHandler"
  >
    <client-only>
      <slot name="header" />

      <div v-if="isReady" class="md:flex items-center hidden font-sans font-normal">
        {{ timeFormatted }}
        <CaretTransition :show="show" class="legacy-time-picker__caret" />
      </div>
      <template v-if="$paDeviceDetector.isMobile()">
        <select
          ref="picker"
          :class="{
            'md:hidden md:pointer-events-none': !show,
            'md:absolute pr-1 md:top-full md:border-gray-200 md:border': show,
          }"
          class="legacy-time-picker__select"
          aria-label="time"
          @blur.stop.prevent="timeChanged(undefined)"
        >
          <option
            v-for="timeItem in legacyTimeList"
            :key="timeItem.value"
            :value="timeItem.value"
            :selected="timeItem.value === value"
          >
            {{ timeItem.title }}
          </option>
        </select>
        <CaretTransition :show="show" class="legacy-time-picker__caret md:hidden" />
      </template>
      <template v-else>
        <LegacyTimePickerDesktop
          :times="legacyTimeList"
          :selected-value="value"
          :expanded="show"
          @selectionChanged="timeChanged($event)"
        />
      </template>
    </client-only>
  </div>
</template>

<script>
import CaretTransition from '~/components/CaretTransition.vue';
import ClickOutside from 'vue-click-outside';
import LegacyTimePickerDesktop from '~/components/custom-search/LegacyTimePickerDesktop.vue';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    CaretTransition,
    LegacyTimePickerDesktop,
  },
  props: {
    isEndTime: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    value: {
      type: String,
      required: false,
      default: () => '12:00',
    },
    threshold: {
      type: String,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      show: false,
      isReady: false,
    };
  },
  computed: {
    isExpanded() {
      return this.show;
    },
    legacyTimeList() {
      const times = [];
      let skip = !!this.threshold;
      let lastSkippedValue;
      for (let i = 0; i < 24; i += 1) {
        for (let j = 0; j < 4; j += 1) {
          const hours = i;
          let hoursFormatted = i;
          let minutes = j * 15;
          let meridiem = '';
          if (this.language.lang === 'en-us') {
            if (hours > 12) {
              hoursFormatted -= 12;
              meridiem = ' PM';
            } else if (hours === 12) {
              meridiem = ' PM';
            } else {
              meridiem = ' AM';
            }
          }

          if (minutes < 10) {
            minutes = `0${minutes}`;
          }
          const val = `${hours}:${minutes}`;
          if (skip) {
            lastSkippedValue = val;
            if (val === this.threshold) {
              skip = false;
            }
            // eslint-disable-next-line no-continue
            continue;
          }
          if (lastSkippedValue === this.value) {
            lastSkippedValue = undefined;
            this.$emit('timeChanged', val, true);
          }
          times.push({
            value: val,
            title: `${hoursFormatted}:${minutes}${meridiem}`,
          });
        }
      }

      return times;
    },
    language() {
      return this.$store.state.language;
    },
    languages() {
      return this.$store.state.languages;
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
    timeFormatted() {
      if (!this.value) {
        return this.language.lang === 'en-us' ? '12:00 PM' : '12:00';
      }
      const [hours, minutes] = this.value.split(':');
      if (this.language.lang === 'en-us') {
        return this.legacyTimeList.filter(
          (time) => time.value === this.value,
        )[0].title;
      }

      return `${hours}:${minutes}`;
    },
  },
  mounted() {
    this.isReady = true;
  },
  methods: {
    timeChanged(providedValue) {
      setTimeout(() => {
        this.show = false;
      });
      this.$emit('timeChanged', providedValue || this.$refs.picker.value);
      this.$store.commit('bucket/update', {
        key: 'pickerInteracted',
        value: this.isEndTime ? 'timeEnd' : 'timeStart',
      });
      this.collapse();
    },
    toggle() {
      if (this.$paDeviceDetector.isMobile()) {
        return;
      }
      if (this.show) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    expand() {
      this.show = true;
      this.$emit('expanded');
    },
    collapse() {
      this.show = false;
      this.$emit('collapsed');
    },
    focusHandler(e) {
      if (e.relatedTarget) {
        if (
          e.relatedTarget.className.includes('date-time-picker')
          || e.relatedTarget.localName === 'button'
        ) {
          this.collapse();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.legacy-time-picker {
    @apply bg-white flex-col relative cursor-pointer whitespace-nowrap font-bold;
  $self: &;

  &:hover {
    #{$self}__caret {
      @apply text-orange-500;
    }
  }

  &__caret {
    @apply absolute md:static bottom-2 right-0 text-gray-400 -translate-x-1/2 md:translate-x-0 pointer-events-none md:pointer-events-auto md:ml-2;
  }

  &__select {
    @apply font-bold bg-white appearance-none bg-transparent pr-6;
    outline: none !important;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 3px;
      @apply bg-silver-100;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-black-alt-300 rounded-md;
    }

    option {
      @apply bg-white p-1 flex items-center;

      &:hover,
      &:checked {
        @apply bg-orange-500 cursor-pointer text-white;
      }
    }
  }
}
</style>
