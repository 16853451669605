// plugins/send-event.js
import { getCookie, nginxDecodeUserId } from '~/helpers/cookieHelpers';

export default ({ $config }, inject) => {
  /**
   * Send event to Flagship Events API
   * @param {string} ea - Event Action
   * @param {string} ec - Event Category
   * @param {string} el - Event Label
   * @returns {Promise<void>}
   */
  const sendEvent = async(ea, ec, el) => {
    if (typeof window === 'undefined') return;

    const visitorIdCookie = getCookie('uid');
    if (!visitorIdCookie) {
      console.log('[FLAGSHIP GTM] VisitorId is not defined');
      return;
    }

    try {
      const { host } = window.location;
      const pageType = window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = getCookie('xpe');
      const flagshipEventsAPIEndpoint = $config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: 'APP',
            ev: 1,
            t: 'EVENT',
            ea,
            ec,
            el,
          }),
          keepalive: true,
        },
      );

      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be sent, HTTP ${response.status}`,
        );
      }
    } catch (error) {
      console.error('[FLAGSHIP GTM] Error sending event:', error);
    }
  };

  // Inject the function directly
  inject('sendEvent', sendEvent);
};
