var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"bg-blue-600 relative leading-normal"},[_c('ReferralCashback'),_vm._v(" "),_c('div',{ref:"highlightElement",staticClass:"bg-blue-600"},[_c('section',{staticClass:"md:container top-menu md:h-auto fixed md:static md:py-7 md:px-10 bg-blue-600 md:bg-transparent w-full md:flex flex-col md:flex-row md:items-center z-30",class:{
        'top-menu--collapse': _vm.collapse,
        'top-menu--scrolled': _vm.scrollTop,
        'h-full': !_vm.collapse,
        flex: !_vm.collapse,
      }},[_c('div',{staticClass:"header-logo-container w-full md:py-0 md:w-auto flex place-content-between px-3 md:px-0 transition-all duration-200",class:{
          'py-1': _vm.scrollTop && _vm.collapse,
          'py-4': !_vm.collapse || !_vm.scrollTop,
        }},[_c('div',{staticClass:"flex items-center"},[_c('a',{staticClass:"top-menu__logo-wrapper p-3 md:-ml-3 -ml-4px",attrs:{"href":"/"},on:{"click":_vm.clear}},[_c('Logo')],1),_vm._v(" "),_c('PartnerLogo')],1),_vm._v(" "),_c('div',{staticClass:"inline-flex items-center"},[_c('a',{attrs:{"href":"/login/"},on:{"click":_vm.clear}},[_c('IconUser',{staticClass:"mr-3 md:hidden w-5 h-5",attrs:{"data-test":"menu-login"}})],1),_vm._v(" "),_c('ToggleMenu',{staticClass:"top-menu__toggle-menu md:hidden",attrs:{"data-test":"menu","open":!_vm.collapse},on:{"toggled":_vm.toggleMenu}})],1)]),_vm._v(" "),_c('nav',{staticClass:"text-xl md:text-base w-screen header-nav h-0 flex-1 flex flex-col md:static top-36 left-0 right-0 bg-white md:bg-transparent overscroll-contain",class:{
          'header-nav--collapse': _vm.collapse,
          'header-nav--mega': _vm.megaMenuOpen,
        }},[_c('div',{ref:"scroller",staticClass:"h-full md:overflow-y-visible w-full flex flex-col md:static md:flex-row md:items-center items-stretch overscroll-contain md:justify-start",class:{
            'overflow-y-scroll': !_vm.isSearchActive && !_vm.languagesExpanded,
            relative: _vm.isSearchActive || _vm.languagesExpanded,
          }},[_c('ul',{staticClass:"header-nav__menu md:mx-auto min-h-max",class:{
              'header-nav__menu--toggled': _vm.megaMenuOpen,
              'hidden md:flex': _vm.languagesExpanded,
            }},[_c('div',{staticClass:"flex flex-col sm:hidden"},[_c('li',{staticClass:"header-nav__menu__item",class:{ 'header-nav__menu__item--sub': _vm.megaMenuOpen },attrs:{"data-test":"menu-airports"}},[_c('div',{staticClass:"text-black md:text-white w-full md:w-auto"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('MegaMenu',{attrs:{"open":_vm.megaMenuOpen,"expression":_vm.searchExpression},on:{"toggled":_vm.megaMenuToggled}})],1)],1)]),_vm._v(" "),(_vm.zenDeskLangCode)?_c('li',{staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":`https://parkos.zendesk.com/hc/${_vm.zenDeskLangCode}`,"rel":"nofollow"},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2",attrs:{"data-test":"menu-customer-service"}},[_vm._v("\n                    "+_vm._s(_vm.$i18n("general.customer-service"))+"\n                  ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]):_vm._e(),_vm._v(" "),(_vm.aboutPageLink)?_c('li',{staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"data-test":"menu-about-us","href":_vm.aboutPageLink},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                    "+_vm._s(_vm.aboutPageTitle)+"\n                  ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]):_vm._e()])]),_vm._v(" "),_c('ul',{staticClass:"header-nav__menu header-nav__menu--abs flex md:flex-row flex-col",class:{
              'header-nav__menu--sub': _vm.languagesExpanded,
              'hidden md:flex': _vm.megaMenuOpen,
            }},[_c('li',{staticClass:"header-nav__menu__item header-nav__menu__item--login"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":"/login/"},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2",attrs:{"data-test":"menu-login"}},[_vm._v("\n                  "+_vm._s(_vm.$i18n("templates.header-login"))+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]),_vm._v(" "),(_vm.faqMeta)?_c('li',{staticClass:"header-nav__menu__item pr-0 header-nav__menu__item--meta"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.faqPageLink},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                  "+_vm._s(_vm.faqPageTitle)+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"header-nav__menu__item pr-0",class:{
                'header-nav__menu__item--toggled': _vm.languagesExpanded,
              }},[_c('MobileLanguages',{staticClass:"block md:hidden",attrs:{"data-test":"menu-languages-mobile","expanded":_vm.languagesExpanded},on:{"toggled":_vm.languagesToggled}}),_vm._v(" "),(!_vm.$paDeviceDetector.isMobile())?_c('Languages',{staticClass:"font-sans hidden md:flex items-center"}):_vm._e()],1)])])])])]),_vm._v(" "),_c('div',{staticClass:"xs:container pt-83px md:pt-12 px-5 md:px-10 md:py-20 relative",class:_vm.be1024homRemoveUsps ? 'pb-10 md:pb-20' : 'pb-18 md:pb-40'},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('MetaReviews')],1),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"text-[20px] xs:text-[24px] font-cocogoose md:text-5xl airport-title-main flex mt-8 mb-3 md:my-7 text-white relative w-7/10 sm:w-full z-1",attrs:{"data-test":"title-compare-parking"}},[_vm._v("\n        "+_vm._s(_vm.$i18n("general.compare-parking"))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"hidden md:block text-white text-base font-sans relative z-1",attrs:{"data-test":"sub-title-compare-parking"}},[_vm._v("\n      "+_vm._s(_vm.$i18n("general.compare-parking-prices-verbose"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col md:block"},[_c('div',{staticClass:"inline-flex flex-col relative w-full",class:{
          'md:pr-16':
            !_vm.isUSDomain && !_vm.isUKDomain && !_vm.isEUDomain && !_vm.isAUDomain,
          'md:pr-2': _vm.isUSDomain || _vm.isUKDomain || _vm.isEUDomain || _vm.isAUDomain,
        },attrs:{"id":"search-section"}},[_c('QuickBookBadge',{attrs:{"show-airports-dropdown":_vm.showAirportsDropdown}}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":"","when-visible":""}},[_c('experiment-toggle',{staticClass:"relative z-1",attrs:{"name":"async-datepicker-vs-custom-datepicker"},scopedSlots:_vm._u([{key:"a",fn:function(){return [_c('Search',{ref:"searchForm",staticClass:"mt-7",class:{ 'mt-2 md:mt-7': _vm.be1024homRemoveUsps },attrs:{"show-airports":_vm.showAirportsDropdown}})]},proxy:true},{key:"b",fn:function(){return [_c('custom-search',{ref:"searchForm",staticClass:"mt-7",class:{ 'mt-2 md:mt-7': _vm.be1024homRemoveUsps },attrs:{"show-airports":_vm.showAirportsDropdown}})]},proxy:true}])})],1)],1),_vm._v(" "),(!_vm.be1024homRemoveUsps)?_c('div',{staticClass:"flex flex-col xs:flex-row place-content-between mt-7",class:{
          'max-w-4xl': !_vm.showAirportsDropdown,
          'max-w-6xl': _vm.showAirportsDropdown,
        }},[_c('div',{staticClass:"order-2 xs:order-1 header-checkmarks flex flex-col md:flex-row mt-5 xs:mt-0",attrs:{"data-test":"header-ups"}},[_c('div',{staticClass:"my-2 md:my-0 font-sans md:mr-10 text-white flex items-center relative",attrs:{"data-test":"header-ups-best-deal"}},[_c('IconCheckmark',{staticClass:"icon-checkmark"}),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$i18n("templates.header-usp-1"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"my-2 md:my-0 font-sans md:mr-10 text-white flex items-center z-1",attrs:{"data-test":"header-ups-number-customers"}},[_c('IconCheckmark',{staticClass:"icon-checkmark"}),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$i18n("templates.header-usp-2"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"my-2 md:my-0 font-sans text-white flex items-center z-1",attrs:{"data-test":"header-ups-free-cancel"}},[_c('IconCheckmark',{staticClass:"icon-checkmark"}),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$i18n("templates.header-usp-3"))+"\n            ")])],1)])]):_vm._e()]),_vm._v(" "),(_vm.be606ukQuickWinsRunning)?_c('be606Usps'):_vm._e(),_vm._v(" "),_c('LazyHydrate',{staticClass:"hidden md:block",attrs:{"when-idle":"","when-visible":""}},[_c('WorldMapParkos',{staticClass:"z-0 worldmap pointer-events-none max-w-3xl absolute -right-56 top-1/2 -mt-12"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }